<template>
  <div class="breadcrumb-container">
    <a
      v-for="(item, index) in items"
      :key="index"
      class="breadcrumb-item"
      :href="item.to"
      draggable="false"
      :class="index === items.length - 1 ? 'black' : ''"
    >{{ item.title }}</a>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        {
          title: 'Home',
          to: '/',
        },
      ],
    },
  },
};
</script>

<style lang="sass" scoped>
.breadcrumb-container
  display: flex
  .breadcrumb-item
    font-size: 11px
    color: #acacac
    &:not(:last-child)::after
      content: url('~@/assets/icons/arrowright.png')
      margin: 0px 10px
    &:hover
      text-decoration: underline
  .black
    color: #262626
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)

//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .breadcrumb-container
    .breadcrumb-item
      font-size: 16px
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
</style>
