<template>
  <div class="page">
    <full-page
      ref="fullpage"
      :options="options"
      id="fullpage"
      v-if="device === 'desktop'"
    >
      <Block0 />
      <Block1 />
      <Block2 />
    </full-page>
    <div v-else>
      <Block0 />
      <Block1 />
      <Block2 />
    </div>
  </div>
</template>

<script>
import Block0 from '@/components/about-us/block0.vue';
import Block1 from '@/components/about-us/block1.vue';
import Block2 from '@/components/about-us/block2.vue';
import Consts from '@/util/consts';

export default {
  components: {
    Block0,
    Block1,
    Block2,
  },
  data() {
    return {
      options: {
        menu: '#menu',
        anchors: ['block0', 'block1', 'block2'],
        licenseKey: Consts.licenseKey,
      },
      windowWidth: document.body.clientWidth,
      windowHeight: document.body.clientHeight,
      device: 'desktop',
    };
  },
  methods: {
    onResize() {
      this.windowWidth = document.body.clientWidth;
      this.windowHeight = document.body.clientHeight;
      if (this.windowWidth >= 1366) {
        this.device = 'desktop';
      } else {
        this.device = 'mobile';
      }
    },
  },
  watch: {
    windowWidth() {},
    windowHeight() {},
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.onResize();
  },
};
</script>

<style lang="sass" scoped>

</style>
