<template>
  <div class="section">
    <Header class="opaque" :opaque="true"/>
    <div class="content">
      <Breadcrumb :items="breadcrumbItems" />
      <div class="container">
        <div class="left">
          <h2>Allstate Cargo</h2>
          <p>As an organization, we are committed to serving our customers with unrivaled
            high-quality service and safety in the trucking industry. Our customers define us with
            the standard they require and our number one priority is their satisfaction. So our
            customers come first, and their opinions matter. We strive towards achieving excellence
            with our services.</p>
          <br>
          <p>To provide these services to our external customers, our internal quality processes is
            at an optimum.  Our operational qualities are built to achieve efficiency.  Every
            employee must understand how their job contributes to the overall operation and directly
            influences the customers, who ultimately have the control knob. Allstate Cargo is
            committed to evolving in all departments with customer needs.</p>
        </div>
        <img src="@/assets/aboutustruck.png" alt="Allstate Cargo truck" draggable="false">
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  components: {
    Header,
    Breadcrumb,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          title: 'Home',
          to: '/',
        },
        {
          title: 'About Us',
          to: '/about-us',
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
.section
  .content
    height: calc(100% - 128px)
    width: 1520px
    margin: 0px auto
    padding-top: 84px
    .container
      display: flex
      margin-top: 136px
      .left
        margin-right: 61px
        h2
          margin-bottom: 70px
        p
          color: #262626
          width: 760px
          line-height: 24px
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  .section
    .content
      height: calc(100% - 80px)
      width: 1320px
      padding-top: 84px
      .container
        margin-top: 80px
        .left
          margin-right: 20px
          h2
            margin-bottom: 40px
          p
            width: 700px
        img
          transform: scale(0.625)
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .section
    background-color: #fff
    .content
      height: auto
      width: 100%
      max-width: 800px
      margin: 0px auto
      padding: 40px 40px 40px 40px
      .container
        display: flex
        flex-direction: column
        margin-top: 30px
        .left
          margin-right: 0px
          margin-bottom: 30px
          h2
            margin-bottom: 30px
          p
            color: #262626
            width: 100%
            font-size: 20px
            line-height: 34px
        img
          width: 100%
          height: auto
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  .section
    background-color: #fff
    .content
      height: auto
      width: 100%
      max-width: 500px
      margin: 0px auto
      padding: 40px 15px 40px 15px
      .container
        display: flex
        flex-direction: column
        margin-top: 30px
        .left
          margin-right: 0px
          margin-bottom: 30px
          h2
            margin-bottom: 30px
          p
            color: #262626
            width: 100%
            line-height: 24px
        img
          width: 100%
          height: auto
</style>
