<template>
  <div class="section">
    <Header class="opaque" :opaque="true"/>
    <div class="content">
      <Breadcrumb :items="breadcrumbItems" />
      <div class="container">
        <div class="case-item">
          <div class="line"></div>
          <h3>Our History</h3>
          <p>We have been through strategic changes over the years, and that has prepared us to
            face any challenge. Our job is to provide our customers with highly efficient
            transportation of their freights.</p>
          <img src="@/assets/aboutus-case1.png" alt="Our history" draggable="false">
        </div>
        <div class="case-item">
          <div class="line"></div>
          <h3>Vision & Purpose</h3>
          <p>As an organization, we are committed to serving our customers with unrivaled
            high-quality service and safety in the trucking industry.</p>
          <img src="@/assets/aboutus-case2.png" alt="Vision & Purpose" draggable="false">
        </div>
        <div class="case-item">
          <div class="line"></div>
          <h3>Why Choose Us</h3>
          <p>As an organization, we are committed to serving our customers with unrivaled
            high-quality service and safety in the trucking industry.</p>
          <img src="@/assets/aboutus-case3.png" alt="Why Choose Us" draggable="false">
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Header,
    Breadcrumb,
    Footer,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          title: 'Home',
          to: '/',
        },
        {
          title: 'About Us',
          to: '/about-us',
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
.section
  .content
    height: calc(100% - 128px - 170px)
    width: 1520px
    margin: 0px auto
    padding-top: 84px
    .container
      display: flex
      margin-top: 27px
      .case-item
        margin-right: 26px
        &:last-child
          margin-right: 0px
        .line
          width: 100%
          height: 2px
          background-color: var(--accent-color)
        h3
          font-weight: 500
          font-size: 35px
          line-height: 46px
          color: #262626
          margin-top: 21px
        p
          font-size: 11px
          line-height: 19px
          color: #262626
          margin-top: 12px
          height: 60px
        img
          margin-top: 20px
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  .section
    .content
      height: calc(100% - 80px - 120px)
      width: 1320px
      padding-top: 60px
      .container
        width: 100%
        .case-item
          width: 33%
          h3
            font-weight: 500
            font-size: 30px
            line-height: 41px
          img
            width: 100%
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .section
    background-color: #fff
    .content
      height: auto
      width: 100%
      max-width: 800px
      margin: -2px auto 0px auto
      padding: 40px 40px 98px 40px
      .breadcrumb-container
        display: none
      .container
        display: flex
        flex-direction: column
        margin-top: 0px
        .case-item
          margin-top: 60px
          margin-right: 0px
          h3
            margin-top: 40px
          p
            font-size: 20px
            line-height: 34px
            height: auto
            margin-top: 32px
          img
            width: 100%
            margin-top: 40px
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  .section
    background-color: #fff
    .content
      height: auto
      width: 100%
      max-width: 500px
      margin: 0px auto
      padding: 40px 15px 40px 15px
      .breadcrumb-container
        display: none
      .container
        display: flex
        flex-direction: column
        margin-top: 0px
        .case-item
          margin-top: 30px
          margin-right: 0px
          h3
          p
          img
            width: 100%
</style>
